<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
.ghost
  opacity: 0.5
  // background: #c8ebfb
  // background-color: #fff !important
  .handle-hover
    opacity: 0 !important
.dragging
  .flip-list-move
    transition: transform 0.5s
  .btn
    cursor: ns-resize
    background-color: #fff !important
.label
  font-weight: 600
  font-size: 0.9rem
  color: #555
.handle
  // cursor: ns-resize
  cursor: pointer
  // .handle-hover
  //   opacity: 0
  &:hover
    .handle-hover
      opacity: 1 !important
</style>
<template lang="pug">

div.async(:class='{done: done}')
  form.form(@submit.prevent='submit()')
    .form-group
      label.d-block 목록에 보이는 항목을 관리합니다.
      //- draggable(:class='{dragging: drag}' v-model='cols' v-bind='drag_options' @start='drag = true' @end='drag = false')
      draggable(:class='{dragging: drag}' v-model='cols' handle='.handle' v-bind='drag_options' @start='drag = true' @end='drag = false')
        transition-group(type='transition' :name="!drag ? 'flip-list' : null")
          div.shadow-sm.bg-white(v-for='col in cols' :key='col.key' style='overflow: hidden;' :style='{height:col.height}')
            form.form-inline(@submit.prevent='')
              table.table.table-bordered.mb-0(style='font-size: 14px')
                tbody
                  tr
                    th.bg-light.handle(style='width: 200px' @click.exact='toggle_height(col)' v-b-tooltip.hover :title='drag ? "" : "클릭하여 펼치기"') {{col.label}}
                      br
                      small.text-muted.text-center.async.handle-hover 드래그하여 순서 변경
                      br
                      .text-center
                        button.btn.btn-default(type='button' @click.stop.prevent='move_up(col)') 위로
                        button.btn.btn-default(type='button' @click.stop.prevent='move_down(col)') 아래로
                    td
                      template(v-if='col.format == `text`')
                        input.form-control.w-100(type='text' placeholder='내용 입력칸 미리보기' disabled)
                        p.text-muted.mb-0.text-left(style='font-weight: normal; white-space: pre') {{col.description}}
                      template(v-else-if='col.format == `check`')
                        b-form-checkbox.rounded-0.text-left(value='Y' unchecked-value='N' disabled)
                          span.d-block.text-left.w-100 {{col.description}}
                      template(v-else-if='col.format == `textarea`')
                        textarea.form-control.w-100(rows=10 placeholder='내용 입력칸 미리보기' disabled)
                        p.text-muted.mb-0.text-left(style='font-weight: normal; white-space: pre') {{col.description}}
                      template(v-else)
                        input.form-control.w-100(type='text' disabled value='내용 입력칸 미리보기')
                    td.bg-light(style='width: 400px')
                      button.btn.btn-default.text-danger.btn-sm.float-right(v-if='col.editable' type='button' @click.prevent='del_col(col)') 삭제
                      .my-2
                        strong.d-block.text-secondary 항목이름
                        span.mr-2(v-if='col.editable')
                          input.form-control(type='text' v-model='col.label')
                        span.mr-2(v-else)
                          input.form-control(type='text' :value='col.description || col.label' readonly)

                      .my-3
                        strong.d-block.text-secondary 테이블표시
                        select.mr-2.form-control(v-model='col.position')
                          option(value='hidden') 표시안함 (상세조회)
                          option(value='primary') 제목
                          option(value='secondary') 내용
                      .my-3
                        strong.d-block.text-secondary 데이터형식
                        select.form-control.mt-2.mr-2(v-model='col.format' v-if='col.editable')
                          option(value='text') 짧은 텍스트
                          option(value='textarea') 긴 텍스트
                          option(value='check') 체크박스
                          option(value='@고객연락처') @고객전화번호
                          option(value='@고객이름') @고객이름
                          option(value='@고객이메일') @고객이메일
                        span(v-else) {{col.format}}

                      span(v-if='col.editable')
                        .my-3
                          strong.d-block.text-secondary.mt-2 입력칸 설명표시
                          textarea.form-control(type='text' v-model='col.description' style='font-size: 12px; width: 20rem' placeholder='내용 입력하거나 수정할때 도움되는 설명내용을 입력칸 아래에 표시합니다.')

              //- strong.mr-2: small 항목이름
              //- span.mr-2(v-if='col.editable')
              //-   input.form-control(type='text' v-model='col.label')
              //- span.mr-2(v-else)
              //-   input.form-control(type='text' :value='col.description || col.label' readonly)
              //- strong.mr-2: small 테이블표시
              //- select.mr-2.form-control(v-model='col.position')
              //-   option(value='hidden') 표시안함 (상세조회)
              //-   option(value='primary') 제목
              //-   option(value='secondary') 내용
              //- strong.mr-2(v-if='col.editable'): small 데이터형식
              //- select.form-control.mt-2.mr-2(v-model='col.format' v-if='col.editable')
              //-   option(value='text') 짧은 텍스트
              //-   option(value='textarea') 긴 텍스트
              //-   option(value='check') 체크박스
              //- span.mr-2.pt-2(v-if='col.editable')
              //-   strong.mr-2: small 입력칸 아래에 표시
              //-   textarea.form-control(type='text' v-model='col.description' style='font-size: 12px; width: 20rem' placeholder='내용 입력하거나 수정할때 도움되는 설명내용을 입력칸 아래에 표시합니다.')




          //- div(v-for='col in cols' :key='col.key')
            label.py-3.btn.btn-default.btn-block.text-left.handle

              div(v-if='col.editable')
                .row(style='margin-top: -0.5rem')
                  .col.pr-0(style='width: 20rem')
                    input.form-control(type='text' v-model='col.label')
                  .col
                    button.btn.btn-default.text-danger.btn-sm(type='button' @click.prevent='del_col(col)') 삭제
              div(v-else)  {{col.description || col.label}}

              span.float-right(:class='[(col.visible_at_table == `Y` ? `text-primary` : `text-muted`)]' style='margin-top: -1.2rem') {{ col.visible_at_table == 'Y' ? '표시함' : '표시안함' }}
              //- b-form-checkbox.rounded-0(v-model='col.visible_at_table' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
              //-   div(v-if='col.editable')
              //-     .row(style='margin-top: -0.5rem')
              //-       .col.pr-0(style='width: 20rem')
              //-         input.form-control(type='text' v-model='col.label')
              //-       .col
              //-         button.btn.btn-default.text-danger.btn-sm(type='button' @click.prevent='del_col(col)') 삭제
              //-   div(v-else)  {{col.description || col.label}}

              //- span.float-right(:class='[(col.visible_at_table == `Y` ? `text-primary` : `text-muted`)]' style='margin-top: -1.2rem') {{ col.visible_at_table == 'Y' ? '표시함' : '표시안함' }}

            //- .clearfix
              //- b-icon-arrow-down-up

    //- .form-group.pt-2
      .mb-4
      label 추가 항목이 필요하신가요?
        button.btn.btn-light.text-primary.ml-4(type='button' @click='add_col()') 항목 추가하기
      //- pre {{cols}}

    .clearfix
    .form-group
      //- button.btn.btn-default.text-danger.float-left(type='button' @click='remove()') 삭제
      button.btn.btn-primary.mr-2(type='submit' :disabled='saving') {{saved_text}}
      //- template(v-slot:back)
      slot(name='back')
        router-link.btn.btn-light.text-secondary.mr-2(:to='`/property/${property_id}/customer/${document_id}`') 뒤로가기
      span.label.float-right 추가 항목이 필요하신가요?
        button.btn.btn-light.text-primary.ml-4(type='button' @click='add_col()') 항목 추가하기
  .clearfix
  .mb-4

</template>

<script>

import draggable from 'vuedraggable'

export default {
  name: 'index',
  props: ['property_id', 'document_id'],
  components: {
    draggable,
  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
    // property() {
    //   return this.$store.state.property
    // },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    }
  },
  data() {
    return {
      done: false,
      saving: false,
      saved_text: '저장',
      document: {},
      error: '',

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      form: {
        customer_id: 'Y',
        customer_name: 'Y',
        customer_phone: 'Y',
        customer_email: 'Y',
      },

      last_col_id: 10,

      cols: [

      ],
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
    // this.load()
    // this.add_col()
    this.$on('CustomerTableConfig:submit', this.submit)
  },
  methods: {
    toggle_height(col) {
      if (col.height == '50px') {
        console.log('>>')
        col.height = 'inherit'
      } else {
        col.height = '50px'
      }
    },
    move_up(col) {
      let i = 0;
      for (const c of this.cols) {
        if (c.key == col.key) break
        i++
      }
      if (i == 0) return

      const l = this.cols.slice(0, i)
      const r = this.cols.slice(i+1)
      const swap = l.pop()
      const p = [col]
      if (swap) p.push(swap)
      this.cols = [].concat(l, p, r)
    },
    move_down(col) {
      let i = 0;
      for (const c of this.cols) {
        if (c.key == col.key) break
        i++
      }
      if (i == this.cols.length - 1) return

      const l = this.cols.slice(0, i)
      const r = this.cols.slice(i+1)
      const swap = r.shift()
      const p = [col]
      if (swap) p.unshift(swap)
      this.cols = [].concat(l, p, r)
    },
    add_col() {
      this.cols.push({
        key: 'd'+this.last_col_id++,
        label: '항목이름',
        format: 'text',
        visible_at_table: 'N',
        editable: true,
      })
    },
    del_col(col) {
      if (!confirm(`[${col.label}] 항목을 삭제하시겠습니까?`)) return false
      this.cols = this.cols.filter(e => {
        return e.key != col.key
      })
    },
    load() {
      console.log('>>>>>', this.property_id, this.document_id)
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }

      if (!this.document.config) {
        this.document.config = {}
      }
      if (!this.document.config.cols) {
        this.document.config.cols = []
      }
      if (this.document.config.last_col_id) {
        this.last_col_id = this.document.config.last_col_id
      }

      this.cols = this.document.config.cols.slice().map(e => {
        return Object.assign({ height: '50px' }, e)
      })

      if (this.cols.length === 0) {
        // 기본으로 채워넣음
        this.cols = [
          {
            key: 'd0',
            label: '고객 이름',
            format: '@고객이름',
            visible_at_table: 'Y',
            editable: false,
          },
          {
            key: 'd1',
            label: '고객 이메일',
            format: '@고객이메일',
            visible_at_table: 'Y',
            editable: false,
          },
          {
            key: 'd2',
            label: '고객 전화번호',
            description: '고객 전화번호 (휴대폰)',
            format: '@고객연락처',
            visible_at_table: 'Y',
            editable: false,
          },
          {
            key: 'd3',
            label: '고객 아이디',
            description: '고객 아이디 (고유식별번호)',
            format: '@고객아이디',
            visible_at_table: 'Y',
            editable: false,
          },
        ]
      }

      this.done = true
    },
    async submit() {
      try {
        this.saving = true
        this.saved_text = '저장 중'

        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols: this.cols,
            last_col_id: this.last_col_id,
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}`, document)
        if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패')

        await this.$store.dispatch('documents', this.property_id)

        this.saved_text = '저장됨'

        // this.$router.push({
        //   path: `/property/${this.property_id}/customer/${this.document_id}`,
        // })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saved_text = '저장'
      }
      setTimeout(() => {
        this.saving = false
      }, 300);
    },
    async remove() {
      try {
        if(!confirm('정말로 삭제하시나요?')) return true

        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/config`)
        if (r?.data?.message != 'ok') throw new Error('고객목록 삭제 실패')

        await this.$store.dispatch('documents', this.property_id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.$router.push({
          path: `/property/${this.property_id}/customer`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
  },
}
</script>
