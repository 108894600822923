<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 100vh')
    .col-10
      .mb-4
      h3.title 기본 정보
      p(v-show='error')
        h3.subtitle.pb-3 {{error}}
      div.async(:class='{done: done}')
        form.form(@submit.prevent='submit()')
          .form-group
            label.d-block 이름
              span.required *
            input.form-control(type='text' v-model='document.name' required autofocus)

          .form-group
            button.btn.btn-primary.float-left(type='submit' :disabled='saving') {{saved_text}}
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove()') 삭제
            //- button.btn.btn-light.text-secondary.float-right.mr-2(type='button' @click='back()') 뒤로가기
      .clearfix
      br
      br
      br
      h3.title.py-3 항목 관리
      .mb-4
      customer-table-config(:property_id='property_id' :document_id='document_id')
        template(v-slot:back)
          span
          //- router-link.btn.btn-light.text-secondary.mr-2(:to='`/property/${property_id}/customer/${document_id}`') 조회로 가기
      //- router-link.btn.btn-light.text-primary(target='_blank' :to='`/property/${property_id}/customer/${document_id}/start-from-scratch/custom-field`') 항목 관리 열기

</template>

<script>

import CustomerTableConfig from '@/components/CustomerTableConfig'

export default {
  name: 'index',
  props: ['property_id', 'document_id'],
  components: {
    CustomerTableConfig,
  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
    // property() {
    //   return this.$store.state.property
    // },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    }
  },
  data() {
    return {
      done: false,
      saving: false,
      saved_text: '저장',
      document: {},
      error: '',
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
  },
  methods: {
    load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }
      this.error = ''
      this.done = true
    },
    async submit() {
      try {
        this.saving = true
        this.saved_text = '저장 중'
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}`, this.document)
        if (r?.data?.message != 'ok') throw new Error('고객목록 저장 실패')

        await this.$store.dispatch('documents', this.property_id)

        this.saved_text = '저장됨'
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => {
        this.saving = false
      }, 300);
    },
    async remove() {
      try {
        if(!confirm('정말로 삭제하시나요?')) return true

        const r = await this.$http.delete(`/v1/property/${this.property_id}/views/documents/${this.document_id}`)
        if (r?.data?.message != 'ok') throw new Error('고객목록 삭제 실패')

        await this.$store.dispatch('documents', this.property_id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.$router.push({
          path: `/property/${this.property_id}/activity`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
  },
}
</script>
